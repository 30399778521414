.room-list .room-item-card{
    margin-bottom: 20px;
}
.room-list .room-item-card .to-detail-icon{
    font-size: 20px;
}
.room-list .room-item-card .room-item-card-content{
    position: relative;
}
.room-list .room-item-card .to-detail-icon{
    position: absolute;
    right: 24px;
    top: 50%;
    transform: translateY(-50%);
}

.room-list .is-deleteing{
    transform: translateX(-60px);
}
.room-list .delete-icon{
    display: none;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -60px;
    font-size: 20px;
    padding: 10px;
    transform: translateY(-50%);
    box-shadow: 0px -2px 0px 0px #E3E9FF;
}

 .empty-icon{
    display: none;
    background: #fff;
    border-radius: 50%;
    position: absolute;
    top: 50%;
    right: -120px;
    font-size: 20px;
    padding: 10px;
    transform: translateY(-50%);
    box-shadow: 0px -2px 0px 0px #E3E9FF;
}



.room-list .is-deleteing .delete-icon{
    display: inline-block;
}