/*.chat-box{*/
/*  text-align: left;*/
/*  position: absolute;*/
/*  bottom: 0;*/
/*  right: 0;*/
/*  width: 23%;*/
/*  z-index: 10;*/
/*}*/
/*.chat-box .chat-box-title{*/
/*  background: #494E5C;*/
/*  font-size: 14px;*/
/*  padding: 16px 0;*/
/*  text-align: left;*/
/*  border-radius: 4px 4px 0 0;*/
/*  cursor: pointer;*/
/*}*/
/*.chat-box .chat-box-title i{*/
/*  font-size: 18px;*/
/*  vertical-align: middle;*/
/*  padding: 0 16px;*/
/*}*/
/*.chat-box .chat-content{*/
/*  background: #000;*/
/*  height: 352px;*/
/*  position: relative;*/
/*}*/

/*.chat-box .message-list{*/
/*  height: 260px;*/
/*  overflow-y: auto;*/
/*}*/

.chat-box{
  text-align: left;
  position: absolute;
  bottom: 0;
  right: 0;
  width: 23%;
  z-index: 10;
}
.chat-box .chat-box-title{
  background: #494E5C;
  font-size: 14px;
  padding: 16px 0;
  text-align: left;
  border-radius: 4px 4px 0 0;
  cursor: pointer;
}
.chat-box .chat-box-title i{
  font-size: 18px;
  vertical-align: middle;
  padding: 0 16px;
}
.chat-box .chat-content{
  background: #000;
  height: 352px;
  position: relative;
}
.chat-box .chat-content .uploading-tip{
  display: none;
  position: absolute;
  left: 0;
  top: 0;
  height: 20px;
  width: 100%;
  z-index: 100;
  background: #7d9bfd;
}
.uploading-progress{
  text-align: center;
  height: 100%;
  color: #fff;
  background: #3860f4;
  width: 80%;
}
.chat-box .message-list{
  height: 260px;
  overflow-y: auto;
}
/*消息框*/
.chat-box .roster-history-btn{
  cursor: pointer;
  font-size: 12px;
  height: 20px;
  background: #eee;
  line-height: 20px;
  text-align: center;
  color: #000;
}
.chat-box .timeline{
  text-align: center;
  padding: 10px 0;
  font-size: 10px;
  color: #bbb;
}
.chat-box .messageFrame{
  padding: 10px 15px;
  overflow: hidden;
  position: relative;
}
.chat-box .self .rosterInfo{
  float: right;
}
.chat-box .roster .rosterInfo {
  float: left;
}
.chat-box .rosterInfo img{
  box-sizing: border-box;
  width: 24px;
  height: 24px;
  border: 1px #888 dashed;
}
.chat-box .self .contentFrame{
  margin-right: 32px;
}
.chat-box .roster .contentFrame {
  margin-left: 32px;
}
.chat-box .contentFrame .username {
  font-size: 12px;
  color: #FFFFFF;
  padding: 0;
  margin: 0;
}
.chat-box .contentFrame .c_content{
  text-align: left;
  word-break: break-all;
  max-width: 60%;
  padding: 10px 15px;
  line-height: 16px;
  font-size: 16px;
  position: relative;
}

.chat-box .self .c_content{
  float: right;
  background: #5A638C;
  border-radius: 8px 0 8px 8px;
}
.chat-box .roster .c_content {
  float: left;
  background: #1E2024;
  border-radius: 0 8px 8px 8px;
}
.chat-box .content .c_content{
  white-space: pre-line;
}
/*图片消息*/
.chat-box .c_content .loc_frame {
  overflow: hidden;
}
.chat-box .c_content .loc_frame a{
  color: inherit;
  text-decoration: none;
}
.chat-box .c_content img {
  width: 100%;
  height: 100%;
  max-width: 200px;
  max-height: 200px;
}
.chat-box .c_content .loc_frame .loc {
  width: 40px;
  height: 40px;
  font-size: 28px;
  line-height: 40px;
  float: left;
}
.chat-box .c_content .loc_txt {
  margin-left: 5px;
  line-height: 40px;
}
.chat-box .chat_forbidden {
  line-height: 90px;
  text-align: center;
  box-sizing: border-box;
  border-top: 1px dashed #ffddaa;
}