.room-list-warpper{
    color: #0A1633;
    text-align: left;
}
.room-list-warpper .page-content{
    padding: 40px;
}
.room-list-warpper .page-content-title{
    padding-bottom: 28px;
}
.room-message{
    border-bottom: 1px solid #D2D6EA;
    padding: 8px 0;
}
.room-list-warpper .css-qgprqx-ItemSpan-inlineBlockWithVerticalMixin-textStyleMixin,
.room-list-warpper .css-1mzfudk-ItemSpan-inlineBlockWithVerticalMixin-textStyleMixin{
    font-size: 24px;
    font-weight: bold;
}
.room-list-warpper .basics-label{
    color: #526075;
}
.bottom-drawer{
    color: #526075;
}
.bottom-drawer .bottom-drawer-title{
    padding-bottom: 20px;
    font-weight: bold;
}
.bottom-drawer .uc-fe-drawer-content{
    padding: 30px 20px;
    box-sizing: border-box;
    border-radius: 4px 4px 0 0;
}
.bottom-drawer .custom-card-title{
    padding-bottom: 0;
}
.bottom-drawer .uc-fe-button{
    margin-top: 30px;
}
.custom-card-title{
    font-size: 16px;
    font-weight: bold;
    padding: 16px 0;
}
.shart-link-card{
    margin-bottom: 20px;
}
.shart-link-card .link-text{
    padding-bottom: 20px;
}
.shart-link-card .role-icon{
    width: 24px;
    height: 24px;
    border: 1px #EAEDFB dashed;
    margin-right: 8px;
    vertical-align: middle;
}

@media screen and (max-width: 720px) {
    .room-list-warpper .page-content{
        padding: 20px;
    }
}