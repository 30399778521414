/*输入区*/
.chat-box .input-warpper{
    height: 90px;
    background: #070706;
    padding: 0 16px;
}

.chat-box .textarea-Box{
    width: 100%;
    display: flex;
    border: 2px solid #474c5a;
    border-radius: 4px;
    box-sizing: border-box;
}
.chat-box .message-textarea{
    resize: none;
    border: none;
    outline: none;
    padding: 10px;
    line-height: 20px;
    font-size: 16px;
    background: #070706;
    color: #fff;
    white-space:nowrap;
}
.chat-box .textarea-Box .emoji-icon{
    font-size: 20px;
    padding: 14px;
}
.chat-box .btn-box{
    padding: 9px 0;
}
.chat-box .btn-box i{
    font-size: 20px;
    cursor: pointer;
    margin-right: 14px;
    line-height: 26px;
    width: 26px;
    border: 1px #888 dashed;
}
.chat-box .btn-box button{
    float: right;
}
.chat-box .file-input{
    display: none;
}